// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //Step In Dashboard URL
  stepInDashboardURL: 'https://dev.stepin.app/',
  //Variable to define environment development/production
  developmentDB: 'dbDevelopment/',
  //Fireabase
  firebase: {
    apiKey: "AIzaSyDl7O3sqRvkCLOF4xSjmH3ZQgTLJecMNLI",
    authDomain: "steptin-e10db.firebaseapp.com",
    databaseURL: "https://steptin-e10db.firebaseio.com",
    projectId: "steptin-e10db",
    storageBucket: "steptin-e10db.appspot.com",
    messagingSenderId: "258135794135",
    appId: "1:258135794135:web:8769c0a9229c2367f8ab92"
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
