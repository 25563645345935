import { Component, OnInit } from '@angular/core';

//testing
import { NotificationService } from '../../services/notifications/notification.service'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

	//testing
	users_data: any = [];

  constructor(public notificationService: NotificationService,
  				private http: HttpClient) { }

  ngOnInit() {
  }

  //testing
  testSeb(){
  	this.http.get("https://reports.stepin.app/user_activity/2020/hJTa90r7fWOh4KLuTkuxTcQ1fEv2.json").subscribe(data =>{
      this.users_data = data;
    })
  }
}
