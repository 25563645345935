import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//References to firebase to get connect with cloud real time database
import { AngularFireModule} from 'angularfire2';
import { AngularFireDatabaseModule} from 'angularfire2/database';
import { environment } from '../environments/environment';
import { AngularFireAuthModule } from 'angularfire2/auth';

//Angular materials
import { MatProgressBarModule, MatProgressSpinnerModule, MatIconModule, MatGridListModule, MatCardModule, MatTableModule } from '@angular/material';

//Components
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/user/dashboard/dashboard.component';
import { ProjectListComponent } from './components/metrics/project-list/project-list.component';
import { CompanyListingComponent } from './components/company-listing/company-listing.component';
import { MainComponent } from './components/main/main.component';

//HTTP MODULE - FOR QUERY PHP WEB SERVICES
import { HttpClientModule } from '@angular/common/http';

//Import of services
import { NotificationService } from './services/notifications/notification.service';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ProjectListComponent,
    CompanyListingComponent,
    MainComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    //Necessary imports to get firebase started on the app.
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    //Angular materials
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatGridListModule,
    MatCardModule,
    MatTableModule,
    //Http MODULE
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
