import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

	users_data: any = [];
	users_data2: any = [];
	users_data3: any = [];

  constructor(private http: HttpClient) { }

  ngOnInit(){
    this.http.get("../stepin_reports/user_activity/2020/hJTa90r7fWOh4KLuTkuxTcQ1fEv2.json").subscribe(data =>{
      this.users_data = data;
    })
    this.http.get("/home/stepinap/stepin_reports/user_activity/2020/hJTa90r7fWOh4KLuTkuxTcQ1fEv2.json").subscribe(data2 =>{
      this.users_data2 = data2;
    })
  }
}
