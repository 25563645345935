import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

//Imports of services
import { CompaniesService } from '../../services/companies/companies.service';

//Imports of models
import { Company } from '../../models/company';

@Component({
  selector: 'app-company-listing',
  templateUrl: './company-listing.component.html',
  styleUrls: ['./company-listing.component.scss']
})
export class CompanyListingComponent implements OnInit, OnDestroy {

  //Users
  companyList: Company[];

  //Loading views
  companiesLoading: boolean = false;

  //Subscriptions
  companiesSub: Subscription;

  //Store Step In dashboard url
  stepInDashboardURL: string;

  constructor(private companiesService: CompaniesService) {
  	this.stepInDashboardURL = environment.stepInDashboardURL;

  	//Initialize Subscriptions
  	this.companiesSub = new Subscription();
  }

  ngOnInit() {
  	this.getPublicCompanies();
  }

  ngOnDestroy() {
  	//Unsubscribe from all Subscriptions
  	this.companiesSub.unsubscribe();
  }

  /** Retrieve public company info */
  getPublicCompanies(){
  	//Show loading spinner
  	this.companiesLoading = true;
  	//Get companies
  	this.companiesSub.add(this.companiesService.getPublicCompanies().snapshotChanges()
     .subscribe(compList => {
  		this.companyList = [];
  		//For each result
  		compList.forEach(companyPayload => {
  			//Add it to list
  			let company = companyPayload.payload.val() as Company;
        company.uid = companyPayload.key;
        company.value = company.shares * company.sharevalue;
        //With newer version of AngularFire, take(1), 
        //used to read the data only once and unsubscribe automatically, 
        //needs to be wrapped inside a pipe()
        this.companiesService.getCompanyInfoObj(company.uid).valueChanges().pipe(take(1))
         .subscribe(companyObj => {
          company.name = companyObj['name'];
          company.photo = companyObj['photo'];
         })
  			this.companyList.push(company)
  		})
  		//Show loading spinner
  		this.companiesLoading = false;
  	}))
  }

}
