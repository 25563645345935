import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';

//Imports of the auth core from angular and firebase
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import * as firebase from 'firebase/app';

//Import of services
import { NotificationService } from '../../services/notifications/notification.service'

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  //Store environment type (development/production)
  developmentDB: string;

  constructor(private firebaseApp: AngularFireDatabase,
              public notificationService: NotificationService) {
  	this.developmentDB = environment.developmentDB;
  }

  /** Get a list of public companies */
  getPublicCompanies(){
  	return this.firebaseApp.list(this.developmentDB + 'marketplace', 
      ref => ref.orderByChild("sharevalue"));
  }

  /** Get company basic info as object (all or selected node) */
  getCompanyInfoObj(uid: string, node?:string){
    if(node){
      return this.firebaseApp.object(this.developmentDB + 'userinfo/basicinfo/' + uid + '/' + node);
    } else {
      return this.firebaseApp.object(this.developmentDB + 'userinfo/basicinfo/' + uid)
    }
  }


}
